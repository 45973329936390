import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import * as Sentry from "@sentry/react";
const sentryDSN = import.meta.env.VITE_SENTRY_DSN as string;

Sentry.init({
  dsn: sentryDSN,
  integrations: [],
});

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
