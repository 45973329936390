import { Box, Container, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import logo from "../../assets/Logo.png";

export default function Mobile() {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: 4,
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* Logo */}
      <Box sx={{ marginBottom: 4 }}>
        <Link href="/homepage">
          <img src={logo} alt="Company Logo" style={{ maxWidth: "150px" }} />
        </Link>
      </Box>

      {/* Warning Message */}
      <Container
        sx={{
          backgroundColor: theme.palette.background.default,
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: "600px",
          color: theme.palette.text.primary,
          textAlign: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Notice for Mobile Users
        </Typography>
        <Typography variant="body1" paragraph>
          ActQ has been developed for desktop use as it’s optimized for studying
          on a desktop. Please access this website from a desktop.
        </Typography>
      </Container>
    </Box>
  );
}
