import { useEffect, useState } from "react";
import HomeHeader from "../components/Home/HomeHeader.tsx";
import Hero from "../components/Home/Hero.tsx";
import Features from "../components/Home/Features.tsx";
import Testimonials from "../components/Home/Testimonials.tsx";
import Pricing from "../components/Home/Pricing.tsx";
import Footer from "../components/Home/Footer.tsx";
import Mobile from "../components/Home/Mobile.tsx";

export default function HomePage() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const mobileCheck =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );
    setIsMobile(mobileCheck);
  }, []);

  if (isMobile) {
    return <Mobile />;
  }

  return (
    <>
      <HomeHeader />
      <Hero />
      <section id="features-section">
        <Features />
      </section>
      <section id="testimonials-section">
        <Testimonials />
      </section>
      <section id="pricing-section">
        <Pricing />
      </section>
      <Footer />
    </>
  );
}
