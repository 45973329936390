import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const tiers = [
  {
    title: "Free",
    price: "£0",
    description: [
      "5x AI Question Searches Per Week",
      "1x AI Exam Paper Per Week",
      "1x AI Exam Correction",
      "2x AI Mnemonic Creation Per Week",
      "2x AI Flashcard Generation Per Week",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
    buttonUrl: "/signuppage",
  },
  {
    title: "Premium",
    monthlyPrice: "£4.49",
    yearlyPrice: "£39.99",
    description: [
      "Recommended",
      "Unlimited AI Question Searches",
      "Unlimited AI Exam Papers",
      "IAI and Old Syllabus Exams",
      "Premium Search Filters and Parameters",
      "1x AI Exam Correction per day",
      "Better correction model for more accuracy",
      "Unlimited Mnemonic Creation",
      "Unlimited AI Flashcard Generation",
    ],
    buttonText: "Start now",
    buttonVariant: "contained",
    popular: true,
    buttonUrl: "/signuppage",
  },
  {
    title: "Enterprise",
    price: "Contact Us",
    description: [
      "Provide your actuarial student employees with the newest study tool",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
    buttonUrl: "/contactpage",
  },
];

export default function Pricing() {
  const theme = useTheme();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const handleBillingCycleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newCycle: string | null
  ) => {
    if (newCycle !== null) {
      setBillingCycle(newCycle);
    }
  };

  return (
    <Container
      id="pricing"
      sx={{
        py: { xs: 4, sm: 12 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Typography variant="h4" color="text.primary" align="center" gutterBottom>
        Pricing
      </Typography>
      <Typography variant="body1" color="text.secondary" align="center">
        Choose the plan that fits your needs.
      </Typography>

      <ToggleButtonGroup
        value={billingCycle}
        exclusive
        onChange={handleBillingCycleChange}
        aria-label="billing cycle"
        sx={{
          mb: 3,
          "& .MuiToggleButton-root": {
            color: "white",
          },
          "& .Mui-selected": {
            color: theme.palette.primary.main,
          },
        }}
        color="primary"
      >
        <ToggleButton value="monthly" aria-label="monthly">
          Monthly
        </ToggleButton>
        <ToggleButton value="yearly" aria-label="yearly">
          Yearly (Over 25% Savings)
        </ToggleButton>
      </ToggleButtonGroup>

      <Grid container spacing={3} justifyContent="center" ref={ref}>
        {tiers.map((tier, index) => (
          <Grid item key={tier.title} xs={12} sm={6} md={4}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1 + index * 0.2 }}
            >
              <Card
                sx={{
                  p: 3,
                  boxShadow: tier.popular
                    ? `0px 4px 20px ${theme.palette.primary.main}`
                    : `0px`,
                  background: tier.popular
                    ? `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`
                    : `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
                }}
              >
                <Typography variant="h6" align="center">
                  {tier.title}
                </Typography>

                <Typography variant="h2" align="center" gutterBottom>
                  {tier.title === "Premium"
                    ? billingCycle === "yearly"
                      ? tier.yearlyPrice
                      : tier.monthlyPrice
                    : tier.price}
                </Typography>
                <Box>
                  {tier.description.map((line) => (
                    <Box
                      key={line}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mb: 1,
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={{
                          width: 20,
                          color: theme.palette.primary.main,
                        }}
                      />
                      <Typography variant="subtitle2">{line}</Typography>
                    </Box>
                  ))}
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  href={tier.buttonUrl} // Map the URL to the button
                  sx={{
                    mt: 2,
                    color: tier.popular
                      ? theme.palette.primary.contrastText
                      : theme.palette.secondary.contrastText,
                    backgroundColor: tier.popular
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main,
                    "&:hover": {
                      backgroundColor: tier.popular
                        ? theme.palette.primary.dark
                        : theme.palette.secondary.dark,
                      borderColor: tier.popular
                        ? undefined
                        : theme.palette.secondary.light,
                      color: tier.popular
                        ? theme.palette.primary.contrastText
                        : theme.palette.secondary.contrastText,
                    },
                  }}
                >
                  {tier.buttonText}
                </Button>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
