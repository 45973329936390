import { Container, Grid, Typography, Card } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const testimonials = [
  {
    name: "Anish",
    title: "Actuarial Student",
    quote:
      "Using this AI tool has completely transformed the way I prepare for exams. I can easily find specific questions and tailor my practice to focus on key topics. I feel more confident and well-prepared than ever!",
  },
  {
    name: "Anonymous",
    title: "Actuarial Manager",
    quote: "Our employees who use this tool have given it great feedback!",
  },
  {
    name: "Sarah",
    title: "Actuarial Student",
    quote:
      "ActQ has really helped me grasp CP1 after failing a few times. Being able to focus on the areas I was losing marks was extremely helpful.",
  },
];

export default function Testimonials() {
  const theme = useTheme();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <Container
      id="testimonials"
      sx={{
        py: { xs: 4, sm: 12 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Typography variant="h4" color="text.primary" align="center" gutterBottom>
        Testimonials
      </Typography>
      <Typography variant="body1" color="text.secondary" align="center" mb={4}>
        Hear from those who have had early access to our AI-powered study tools
        for the previous sitting.
      </Typography>

      <Grid container spacing={3} justifyContent="center" ref={ref}>
        {testimonials.map((testimonial, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1 + index * 0.2 }}
            >
              <Card
                sx={{
                  p: 3,
                  backgroundColor: theme.palette.grey[900],
                  color: theme.palette.text.primary,
                  boxShadow: 3,
                  borderRadius: 2,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {testimonial.name}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary" mb={2}>
                  {testimonial.title}
                </Typography>
                <Typography variant="body1">"{testimonial.quote}"</Typography>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
