import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Logo from "../General/Logo";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { createClient } from "@supabase/supabase-js";
import Alert from "@mui/material/Alert";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY as string;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

interface MainHeaderProps {
  premiumEnabled: boolean;
  onFeatureSelect: (feature: string) => void;
}

const MainHeader: React.FC<MainHeaderProps> = ({
  premiumEnabled,
  onFeatureSelect,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSignOut = async () => {
    setLoading(true);
    setError(null);

    try {
      const { error } = await supabase.auth.signOut();

      if (error) {
        setError("Failed to sign out. Please try again.");
        setLoading(false);
      } else {
        navigate("/homepage");
      }
    } catch {
      setError(`An unexpected error occurred. Please try again.`);
      setLoading(false);
    }
  };

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        paddingX: theme.spacing(2),
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Logo */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Logo />
        </Box>

        {/* Centered Buttons */}
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            gap: theme.spacing(2),
          }}
        >
          <Button
            sx={{
              color: theme.palette.text.primary,
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
            onClick={() => onFeatureSelect("search")}
          >
            Exam Search
          </Button>
          <Button
            sx={{
              color: theme.palette.text.primary,
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
            onClick={() => onFeatureSelect("corrector")}
          >
            Exam Corrector
          </Button>
          <Button
            sx={{
              color: theme.palette.text.primary,
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
            onClick={() => onFeatureSelect("mnemonic")}
          >
            Mnemonic Maker
          </Button>
          <Button
            sx={{
              color: theme.palette.text.primary,
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
            onClick={() => onFeatureSelect("flashcard")}
          >
            Flashcards
          </Button>
          <Button
            sx={{
              color: theme.palette.text.primary,
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
            href={
              premiumEnabled
                ? "https://billing.stripe.com/p/login/28o4knexx96o2qccMM"
                : "/pricingpage"
            }
            target={premiumEnabled ? "_blank" : undefined}
          >
            Manage Subscription
          </Button>
          <Button
            sx={{
              color: theme.palette.text.primary,
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
            target="_blank"
            href="/contactpage"
          >
            Contact us
          </Button>
        </Box>

        {/* Sign Out Button */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleSignOut}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Sign Out"}
          </Button>
        </Box>
      </Toolbar>

      {/* Display error if sign out fails */}
      {error && (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
    </AppBar>
  );
};

export default MainHeader;
