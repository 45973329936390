import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import Logo from "../General/Logo";
import { createClient } from "@supabase/supabase-js";
import { Turnstile } from "@marsidev/react-turnstile";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY as string;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const turnstileKey = import.meta.env.VITE_TURNSTILE_SITEKEY as string;

export default function ForgotPassword() {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(
    null
  );
  const [captchaToken, setCaptchaToken] = React.useState<string | undefined>(
    undefined
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string | null;

    if (!email) {
      setErrorMessage("Email address must be provided");
      setLoading(false);
      return;
    }

    const emailRegex =
      /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9]|[a-z0-9-]*[a-z0-9]:(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+)\]))$/i;

    if (!emailRegex.test(email)) {
      setErrorMessage("Invalid email format");
      setLoading(false);
      return;
    }

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/resetpasswordpage`,
        captchaToken,
      });
      if (error) {
        setErrorMessage(`Error sending reset link: ${error.message}`);
        setLoading(false);
      } else {
        setSuccessMessage("Password reset link sent! Please check your email.");
        setLoading(false);
      }
    } catch {
      setErrorMessage("An unexpected error occurred. Please try again.");
      setLoading(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        position: "relative",
        backgroundColor: theme.palette.background.default,
        padding: 4,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "150%",
          height: "150%",
          top: "-25%",
          left: "-25%",
          background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
          transform: "rotate(-25deg)",
          zIndex: 1,
        }}
      />

      <Box
        sx={{
          zIndex: 10,
          backgroundColor: theme.palette.background.default,
          borderRadius: 2,
          padding: { xs: 4, md: 6 },
          boxShadow: theme.shadows[5],
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo />
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        >
          <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary.main }}>
            <LockOutlinedIcon />
          </Avatar>
        </motion.div>

        <Typography component="h1" variant="h5" sx={{ fontWeight: "bold" }}>
          Forgot Password
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.secondary,
            mt: 2,
            textAlign: "center",
          }}
        >
          Please enter your email address. We will send you an email to reset
          your password.
        </Typography>

        {errorMessage && (
          <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{ mt: 2, width: "100%" }}>
            {successMessage}
          </Alert>
        )}

        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 3, width: "100%" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.palette.divider,
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.primary.light,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.secondary,
                    },
                    mb: 4,
                  }}
                />
              </motion.div>
            </Grid>
          </Grid>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <Turnstile
              siteKey={turnstileKey}
              options={{ appearance: "always" }}
              onSuccess={(token) => {
                setCaptchaToken(token);
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Send Reset Link"}
            </Button>
          </motion.div>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/signinpage" variant="body2">
                Remembered your password? Sign In
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
