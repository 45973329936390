import { Box, Typography, Container, Button } from "@mui/material";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import Typical from "react-typical";
import backgroundImage from "../../assets/background.png";

const Hero = () => {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "center",
        minHeight: { xs: "100vh", "2xl": "1000px" },
        height: "fit-content",
        gap: 4,
        position: "relative",
        overflow: "hidden",
        backgroundColor: theme.palette.background.default,
        padding: { xs: 4, md: 8 },
      }}
    >
      {/* Background Animation */}
      <Box
        sx={{
          position: "absolute",
          width: "150%",
          height: "150%",
          top: "-25%",
          left: "-25%",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: 1,
        }}
      />

      {/* Text */}
      <Container
        sx={{
          zIndex: 10,
          textAlign: { xs: "center", md: "left" },
          gap: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "flex-start" },
          maxWidth: { xs: "100%", md: "70%" },
          color: theme.palette.text.primary,
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <Typography variant="h1" sx={{ fontWeight: "bold" }}>
            Helping you{" "}
            <span
              style={{
                display: "inline-block",
                color: theme.palette.primary.main,
              }}
            >
              <Typical
                steps={[
                  "pass",
                  3000,
                  "ace",
                  3000,
                  "smash",
                  3000,
                  "conquer",
                  3000,
                ]}
                loop={Infinity}
                wrapper="span"
              />
            </span>
          </Typography>
          <Typography variant="h1" sx={{ fontWeight: "bold" }}>
            your actuarial exams
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <Typography variant="h5" sx={{ marginTop: 2 }}>
            Enhance your learning with our{" "}
            <span style={{ color: theme.palette.primary.main }}>
              AI powered
            </span>{" "}
            tools.
          </Typography>
          <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
            * Not usable for Excel based exams and CP3
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <Button
            variant="contained"
            size="large"
            href="/signuppage"
            sx={{
              marginTop: 2,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Get Started
          </Button>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Hero;
