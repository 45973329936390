import { Box, Container, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import logo from "../../assets/Logo.png"; // Path to your logo

const Compliance = () => {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: 4,
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* Logo */}
      <Box sx={{ marginBottom: 4 }}>
        <Link href="/homepage">
          <img src={logo} alt="Company Logo" style={{ maxWidth: "150px" }} />
        </Link>
      </Box>

      {/* Compliance Text */}
      <Container
        sx={{
          backgroundColor: theme.palette.background.default,
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: "800px",
          color: theme.palette.text.primary,
        }}
      >
        {/* Terms of Service */}
        <Typography variant="h3" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Last Updated: September 2024
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to ActQ! These Terms of Service ("Terms") govern your access
          to and use of the ActQ website (the "Service"). By accessing or using
          the Service, you agree to comply with and be bound by these Terms.
          Please read them carefully before using the Service.
        </Typography>

        <Typography variant="h4" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing or using ActQ, you acknowledge that you have read,
          understood, and agree to be bound by these Terms. If you do not agree
          to these Terms, you may not use the Service.
        </Typography>

        <Typography variant="h4" gutterBottom>
          2. Service Overview
        </Typography>
        <Typography variant="body1" paragraph>
          ActQ provides an online platform offering educational tools and
          resources through paid subscriptions. Access to certain features may
          require the creation of an account and subscription.
        </Typography>

        {/* Continue this structure for other sections of the ToS */}
        <Typography variant="h4" gutterBottom>
          3. User Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          To access certain features of the Service, you may be required to
          create an account. You agree to: - Provide accurate and up-to-date
          information during registration. - Maintain the confidentiality of
          your account credentials. - Be responsible for all activities that
          occur under your account.
        </Typography>

        <Typography variant="h4" gutterBottom>
          4. Subscriptions and Payments
        </Typography>
        <Typography variant="body1" paragraph>
          The Service offers paid subscriptions, which may be billed on a
          recurring basis. Payment processing is handled by a third-party
          provider (e.g., Stripe). By subscribing, you authorize us to charge
          your selected payment method for subscription fees.
        </Typography>

        {/* You can add the rest of the Terms of Service content here following this format */}

        {/* Privacy Policy */}
        <Typography variant="h3" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Last Updated: September 2024
        </Typography>
        <Typography variant="body1" paragraph>
          This Privacy Policy outlines how ActQ ("we," "our," "us") collects,
          uses, and shares your personal information when you use our Service.
        </Typography>

        <Typography variant="h4" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect the following information when you use the Service: -
          Account Information: When you create an account, we collect your email
          address. - Payment Information: If you subscribe to our paid services,
          we collect payment details (such as credit card information) via our
          third-party payment processor (e.g., Stripe).
        </Typography>

        {/* Continue with Privacy Policy sections */}
        <Typography variant="h4" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect for the following purposes: - To
          provide and maintain the Service. - To process payments and manage
          subscriptions. - To communicate with you regarding your account,
          updates, and promotions. - To improve the functionality and
          performance of the Service.
        </Typography>

        {/* Add the rest of the Privacy Policy content here */}

        {/* Closing Paragraph */}
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms or the Privacy Policy,
          please contact us through our{" "}
          <Link href="/contactpage">Contact Page</Link>.
        </Typography>
      </Container>
    </Box>
  );
};

export default Compliance;
