import { Box, Container, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function Footer() {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        backgroundColor: theme.palette.background.default,
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} ActQ. All rights reserved.
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Link
            href="compliancepage"
            color="inherit"
            underline="hover"
            sx={{ textDecoration: "none", fontSize: "0.875rem" }}
          >
            Privacy Policy
          </Link>
          <Link
            href="compliancepage"
            color="inherit"
            underline="hover"
            sx={{ textDecoration: "none", fontSize: "0.875rem" }}
          >
            Terms of Service
          </Link>
          <Link
            href="contactpage"
            color="inherit"
            underline="hover"
            sx={{ textDecoration: "none", fontSize: "0.875rem" }}
          >
            Contact Us
          </Link>
        </Box>
      </Container>
    </Box>
  );
}
