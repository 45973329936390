import { Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Logo = () => {
  const theme = useTheme();

  return (
    <Link href="/homepage" sx={{ textDecoration: "none" }}>
      <Typography
        variant="h3"
        component="div"
        sx={{ fontWeight: "bold", cursor: "pointer" }}
      >
        <span style={{ color: theme.palette.secondary.main }}>¿</span>
        <span style={{ color: theme.palette.primary.main }}>ActQ</span>
        <span style={{ color: theme.palette.secondary.main }}>?</span>
      </Typography>
    </Link>
  );
};

export default Logo;
