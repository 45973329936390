import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  createClient,
  FunctionsHttpError,
  FunctionsRelayError,
  FunctionsFetchError,
} from "@supabase/supabase-js";
import LinearProgress from "@mui/material/LinearProgress";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY as string;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

interface FlashcardMakerProps {
  userId: string;
  premiumEnabled: boolean;
  flashcardRL: number;
  flashcardResetRL: string;
}

type Subject = {
  id: number;
  subject: string;
};

type Topic = {
  id: number;
  subject_id: number;
  topic: string;
};

type Flashcard = {
  id: number;
  topic_id: number;
  front: string;
  back: string;
};

const FlashcardMaker: React.FC<FlashcardMakerProps> = ({
  userId,
  premiumEnabled,
  flashcardRL,
  flashcardResetRL,
}) => {
  const theme = useTheme();

  //Get initial data
  useEffect(() => {
    getSubjects();
    getTopics();
    getFlashcards();
  }, [userId]);

  //Subject
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSubjectId, setSelectedSubjectId] = useState<number | null>(
    null
  );
  const [newSubject, setNewSubject] = useState<string>("");
  const [editSubject, setEditSubject] = useState<string>("");
  const [openAddSubjectModal, setOpenAddSubjectModal] = useState(false);
  const [openEditSubjectModal, setOpenEditSubjectModal] = useState(false);
  const [openDeleteSubjectConfirmModal, setOpenDeleteSubjectConfirmModal] =
    useState(false);

  const handleOpenAddSubjectModal = () => setOpenAddSubjectModal(true);
  const handleCloseAddSubjectModal = () => setOpenAddSubjectModal(false);
  const handleOpenEditSubjectModal = () => setOpenEditSubjectModal(true);
  const handleCloseEditSubjectModal = () => setOpenEditSubjectModal(false);
  const handleOpenDeleteSubjectConfirmModal = () =>
    setOpenDeleteSubjectConfirmModal(true);
  const handleCloseDeleteSubjectConfirmModal = () =>
    setOpenDeleteSubjectConfirmModal(false);

  const handleAddSubjectModal = () => {
    handleOpenAddSubjectModal();
    setSelectedSubject("");
    setSelectedSubjectId(null);
  };
  const handleEditSubjectModal = () => {
    const subject = subjects.find((sub) => sub.subject === selectedSubject);
    if (subject) {
      setEditSubject(subject.subject);
      handleOpenEditSubjectModal();
    }
  };

  const getSubjects = async () => {
    try {
      const { data, error } = await supabase
        .from("flashcard_subject")
        .select("id, subject")
        .eq("user_id", userId);

      if (error) {
        console.error("Error fetching subjects:", error);
      } else {
        setSubjects(data as Subject[]);
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const handleAddSubject = async () => {
    try {
      const { error } = await supabase
        .from("flashcard_subject")
        .insert({ subject: newSubject, user_id: userId });
      if (error) {
        console.error("Error adding subject:", error);
      } else {
        setNewSubject("");
        getSubjects();
        setOpenAddSubjectModal(false);
        setSelectedSubject("");
        setSelectedTopic("");
        setSelectedSubjectId(null);
        setSelectedTopicId(null);
      }
    } catch (error) {
      console.error("Error adding subject:", error);
      setSelectedSubject("");
      setSelectedTopic("");
      setSelectedSubjectId(null);
      setSelectedTopicId(null);
    }
  };

  const handleEditSubject = async () => {
    try {
      const { error } = await supabase
        .from("flashcard_subject")
        .update({ subject: editSubject, user_id: userId })
        .eq("user_id", userId)
        .eq("id", selectedSubjectId);
      if (error) {
        console.error("Error updating subject:", error);
      } else {
        getSubjects();
        setOpenEditSubjectModal(false);
        setSelectedSubject("");
        setSelectedTopic("");
        setSelectedSubjectId(null);
        setSelectedTopicId(null);
      }
    } catch (error) {
      console.error("Error updating subject:", error);
      setSelectedSubject("");
      setSelectedTopic("");
      setSelectedSubjectId(null);
      setSelectedTopicId(null);
    }
  };

  const handleDeleteSubject = async () => {
    try {
      const { error } = await supabase
        .from("flashcard_subject")
        .delete()
        .eq("user_id", userId)
        .eq("id", selectedSubjectId);
      if (error) {
        console.error("Error deleting subject:", error);
      } else {
        getSubjects();
        setOpenEditSubjectModal(false);
        setSelectedSubject("");
        setSelectedTopic("");
        setSelectedSubjectId(null);
        setSelectedTopicId(null);
      }
    } catch (error) {
      console.error("Error deleting subject:", error);
      setSelectedSubject("");
      setSelectedTopic("");
      setSelectedSubjectId(null);
      setSelectedTopicId(null);
    }
  };

  //Topic
  const [topics, setTopics] = useState<Topic[]>([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedTopicId, setSelectedTopicId] = useState<number | null>(null);
  const [newTopic, setNewTopic] = useState<string>("");
  const [editTopic, setEditTopic] = useState<string>("");
  const [openAddTopicModal, setOpenAddTopicModal] = useState(false);
  const [openEditTopicModal, setOpenEditTopicModal] = useState(false);
  const [openDeleteTopicConfirmModal, setOpenDeleteTopicConfirmModal] =
    useState(false);

  const handleOpenAddTopicModal = () => setOpenAddTopicModal(true);
  const handleCloseAddTopicModal = () => setOpenAddTopicModal(false);
  const handleOpenEditTopicModal = () => setOpenEditTopicModal(true);
  const handleCloseEditTopicModal = () => setOpenEditTopicModal(false);
  const handleOpenDeleteTopicConfirmModal = () =>
    setOpenDeleteTopicConfirmModal(true);
  const handleCloseDeleteTopicConfirmModal = () =>
    setOpenDeleteTopicConfirmModal(false);

  const handleAddTopicModal = () => {
    handleOpenAddTopicModal();
    setSelectedTopic("");
    setSelectedTopicId(null);
  };
  const handleEditTopicModal = () => {
    const topic = topics.find((sub) => sub.topic === selectedTopic);
    if (topic) {
      setEditTopic(topic.topic);
      setSelectedTopicId(topic.id);
      handleOpenEditTopicModal();
    }
  };

  const getTopics = async () => {
    try {
      const { data, error } = await supabase
        .from("flashcard_topic")
        .select("id, subject_id, topic")
        .eq("user_id", userId);


      if (error) {
        console.error("Error fetching topics:", error);
      } else {
        setTopics(data as Topic[]);
      }
    } catch (error) {
      console.error("Error fetching topics:", error);
    }
  };

  const handleAddTopic = async () => {
    try {
      const { error } = await supabase.from("flashcard_topic").insert({
        topic: newTopic,
        user_id: userId,
        subject_id: selectedSubjectId,
      });
      if (error) {
        console.error("Error adding topic:", error);
      } else {
        setNewTopic("");
        setSelectedTopic("");
        setSelectedTopicId(null);
        getTopics();
        getFlashcards();
        setOpenAddTopicModal(false);
      }
    } catch (error) {
      console.error("Error adding topic:", error);
      setSelectedTopic("");
      setSelectedTopicId(null);
    }
  };

  const handleEditTopic = async () => {
    try {
      const { error } = await supabase
        .from("flashcard_topic")
        .update({ topic: editTopic, user_id: userId })
        .eq("user_id", userId)
        .eq("id", selectedTopicId);
      if (error) {
        console.error("Error updating topic:", error);
      } else {
        getTopics();
        getFlashcards();
        setOpenEditTopicModal(false);
        setSelectedTopic("");
        setSelectedTopicId(null);
      }
    } catch (error) {
      console.error("Error updating topic:", error);
      setSelectedTopic("");
      setSelectedTopicId(null);
    }
  };

  const handleDeleteTopic = async () => {
    try {
      const { error } = await supabase
        .from("flashcard_topic")
        .delete()
        .eq("user_id", userId)
        .eq("id", selectedTopicId);
      if (error) {
        console.error("Error deleting topic:", error);
      } else {
        getTopics();
        getFlashcards();
        setOpenEditTopicModal(false);
        setSelectedTopic("");
        setSelectedTopicId(null);
      }
    } catch (error) {
      console.error("Error deleting topic:", error);
      setSelectedTopic("");
      setSelectedTopicId(null);
    }
  };

  //Flashcard
  const [flashcards, setFlashcards] = useState<Flashcard[]>([]);
  const [selectedFlashcardId, setSelectedFlashcardId] = useState<number | null>(
    null
  );
  const [newFlashcardFront, setNewFlashcardFront] = useState<string>("");
  const [newFlashcardBack, setNewFlashcardBack] = useState<string>("");
  const [editFlashcardFront, setEditFlashcardFront] = useState<string>("");
  const [editFlashcardBack, setEditFlashcardBack] = useState<string>("");
  const [generateFlashcardNotes, setGenerateFlashcardNotes] =
    useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [wordCount, setWordCount] = useState(0);
  const [progressText, setProgressText] = useState<string>("");

  const [openAddFlashcardModal, setOpenAddFlashcardModal] = useState(false);
  const [openEditFlashcardModal, setOpenEditFlashcardModal] = useState(false);
  const [openDeleteFlashcardConfirmModal, setOpenDeleteFlashcardConfirmModal] =
    useState(false);
  const [openGenerateFlashcardModal, setOpenGenerateFlashcardModal] =
    useState(false);

  const handleOpenAddFlashcardModal = () => setOpenAddFlashcardModal(true);
  const handleCloseAddFlashcardModal = () => setOpenAddFlashcardModal(false);
  const handleOpenEditFlashcardModal = (flashcard: Flashcard) => {
    setSelectedFlashcardId(flashcard.id);
    setEditFlashcardFront(flashcard.front);
    setEditFlashcardBack(flashcard.back);
    setOpenEditFlashcardModal(true);
  };
  const handleCloseEditFlashcardModal = () => setOpenEditFlashcardModal(false);
  const handleOpenDeleteFlashcardConfirmModal = () =>
    setOpenDeleteFlashcardConfirmModal(true);
  const handleCloseDeleteFlashcardConfirmModal = () =>
    setOpenDeleteFlashcardConfirmModal(false);
  const handleOpenGenerateFlashcardModal = () =>
    setOpenGenerateFlashcardModal(true);
  const handleCloseGenerateFlashcardModal = () =>
    setOpenGenerateFlashcardModal(false);

  const getFlashcards = async () => {
    try {
      const { data, error } = await supabase
        .from("flashcard_detail")
        .select("id, topic_id, front, back")
        .eq("user_id", userId);


      if (error) {
        console.error("Error fetching flashcards:", error);
      } else {
        setFlashcards(data as Flashcard[]);
      }
    } catch (error) {
      console.error("Error fetching flashcards:", error);
    }
  };

  const handleAddFlashcard = async () => {
    try {
      const { error } = await supabase.from("flashcard_detail").insert({
        topic_id: selectedTopicId,
        front: newFlashcardFront,
        back: newFlashcardBack,
        user_id: userId,
      });
      if (error) {
        console.error("Error adding flashcard:", error);
      } else {
        setNewFlashcardFront("");
        setNewFlashcardBack("");
        getFlashcards();
        setOpenAddFlashcardModal(false);
      }
    } catch (error) {
      console.error("Error adding flashcard:", error);
    }
  };

  const handleEditFlashcard = async () => {
    try {
      const { error } = await supabase
        .from("flashcard_detail")
        .update({ front: editFlashcardFront, back: editFlashcardBack })
        .eq("user_id", userId)
        .eq("id", selectedFlashcardId);
      if (error) {
        console.error("Error updating flashcard:", error);
      } else {
        getFlashcards();
        setOpenEditFlashcardModal(false);
      }
    } catch (error) {
      console.error("Error updating flashcard:", error);
    }
  };

  const handleDeleteFlashcard = async () => {
    try {
      const { error } = await supabase
        .from("flashcard_detail")
        .delete()
        .eq("user_id", userId)
        .eq("id", selectedFlashcardId);
      if (error) {
        console.error("Error deleting flashcard:", error);
      } else {
        getFlashcards();
        setOpenEditFlashcardModal(false);
      }
    } catch (error) {
      console.error("Error deleting flashcard:", error);
    }
  };

  //Generate Flashcards
  const handleGenerateFlashcards = async () => {
    startLoadingProcess();
    try {
      const payload = {
        topic_id: selectedTopicId,
        notes: generateFlashcardNotes,
      };

      const { data, error } = await supabase.functions.invoke(
        "generate_flashcards",
        {
          body: JSON.stringify(payload),
        }
      );

      if (error) {
        if (error instanceof FunctionsHttpError) {
          const errorMessage = await error.context.json();
          setErrorMessage(errorMessage.error || "HTTP Error occurred");
          setLoading(false);
        } else if (error instanceof FunctionsRelayError) {
          setErrorMessage(`Relay error: ${error.message}`);
          setLoading(false);
        } else if (error instanceof FunctionsFetchError) {
          setErrorMessage(`Fetch error: ${error.message}`);
          setLoading(false);
        } else {
          setErrorMessage("An unknown error occurred.");
          setLoading(false);
        }
        return;
      }

      getFlashcards();
      setSuccessMessage(data.response);
      setSelectedTopic("");
      setSelectedTopicId(null);
      setGenerateFlashcardNotes("");
      setLoading(false);
    } catch (err) {
      setErrorMessage((err as Error).message);
      setLoading(false);
    }
  };

  const startLoadingProcess = () => {
    setLoading(true);
    setErrorMessage("");
    setProgressText("Starting search...");
    const progressSteps = [
      "Sending your notes to our servers",
      "Generating flashcards from your notes",
      "Formatting your flashcards",
      "Using AI Magic",
    ];

    progressSteps.forEach((text, index) => {
      setTimeout(() => setProgressText(text), index * 10000);
    });
  };

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        p: 4,
        borderRadius: 2,
        backgroundColor: theme.palette.grey[900],
        boxShadow: theme.shadows[3],
        maxWidth: "60%",
        mx: "auto",
        px: { xs: 2, md: 4 },
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: theme.palette.text.primary }}
      >
        Flashcards
      </Typography>

      {/* Subject Dropdown */}
      <InputLabel id="select-subjects">Select Subject</InputLabel>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mb: 3 }}>
        <Box sx={{ width: "70%" }}>
          <Select
            value={selectedSubjectId}
            onChange={(e) => {
              const selectedId = e.target.value;
              const selectedItem = subjects.find(
                (item) => item.id === selectedId
              );
              const selectedValue = selectedItem ? selectedItem.subject : "";
              setSelectedSubject(selectedValue);
              setSelectedSubjectId(Number(selectedId));
              setSelectedTopic("");
              setSelectedTopicId(null);
            }}
            input={<OutlinedInput />}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.divider,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.light,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.main,
              },
            }}
            MenuProps={{
              disableAutoFocusItem: true,
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.background.default,
                  color: theme.palette.text.primary,
                  maxHeight: 300,
                },
              },
            }}
          >
            {subjects.map(({ id, subject }) => (
              <MenuItem key={id} value={id}>
                {subject}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            width: "30%",
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddSubjectModal}
            sx={{
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Add Subject
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleEditSubjectModal}
            sx={{
              backgroundColor: theme.palette.secondary.main,
              "&:hover": {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
            disabled={!selectedSubject}
          >
            Edit Subject
          </Button>
        </Box>
      </Box>

      {/* Topics  */}
      {selectedSubject && (
        <>
          <InputLabel id="select-subjects">Select Topic</InputLabel>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 3,
              mb: 3,
            }}
          >
            <Box sx={{ width: "70%" }}>
              <Select
                value={selectedTopicId}
                onChange={(e) => {
                  const selectedId = e.target.value;
                  const selectedItem = topics.find(
                    (item) => item.id === selectedId
                  );
                  const selectedValue = selectedItem ? selectedItem.topic : "";
                  setSelectedTopic(selectedValue);
                  setSelectedTopicId(Number(selectedId));
                }}
                input={<OutlinedInput />}
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.background.default,
                  color: theme.palette.text.primary,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.divider,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.light,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                  },
                }}
                MenuProps={{
                  disableAutoFocusItem: true,
                  PaperProps: {
                    sx: {
                      backgroundColor: theme.palette.background.default,
                      color: theme.palette.text.primary,
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {topics
                  .filter((topic) => topic.subject_id === selectedSubjectId)
                  .map((topic) => (
                    <MenuItem key={topic.id} value={topic.id}>
                      {topic.topic}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            <Box
              sx={{
                width: "30%",
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddTopicModal}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                Add Topic
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleEditTopicModal}
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                  },
                }}
                disabled={!selectedTopic}
              >
                Edit Topic
              </Button>
            </Box>
          </Box>
        </>
      )}

      {/* Flashcards */}
      {selectedTopic && (
        <>
          <Box sx={{ mb: 4 }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenAddFlashcardModal}
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                Add Flashcard
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpenGenerateFlashcardModal}
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.secondary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                  },
                }}
              >
                Generate Flashcards
              </Button>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: 3,
              }}
            >
              {flashcards
                .filter((flashcard) => flashcard.topic_id === selectedTopicId)
                .map((flashcard) => (
                  <Box
                    key={flashcard.id}
                    sx={{
                      width: "100%",
                    }}
                  >
                    {/* Flashcard */}
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: 200,
                        perspective: "1000px",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          borderRadius: 2,
                          transformStyle: "preserve-3d",
                          transition: "transform 0.6s",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          const target = e.currentTarget;
                          target.style.transform =
                            target.style.transform === "rotateY(180deg)"
                              ? "rotateY(0deg)"
                              : "rotateY(180deg)";
                        }}
                      >
                        {/* Front */}
                        <Box
                          sx={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            backfaceVisibility: "hidden",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 2,
                            backgroundColor: theme.palette.grey[800],
                            boxShadow: theme.shadows[1],
                            padding: 2,
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ textAlign: "center" }}
                          >
                            {flashcard.front}
                          </Typography>
                        </Box>

                        {/* Back */}
                        <Box
                          sx={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            backfaceVisibility: "hidden",
                            transform: "rotateY(180deg)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 2,
                            backgroundColor: theme.palette.grey[800],
                            boxShadow: theme.shadows[1],
                            padding: 2,
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ textAlign: "center" }}
                          >
                            {flashcard.back}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {/* Edit Button */}
                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleOpenEditFlashcardModal(flashcard)}
                        sx={{
                          backgroundColor: theme.palette.secondary.main,
                          "&:hover": {
                            backgroundColor: theme.palette.secondary.dark,
                          },
                        }}
                      >
                        Edit
                      </Button>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </>
      )}

      {/* Add Subject Dialog */}
      <Dialog
        open={openAddSubjectModal}
        onClose={handleCloseAddSubjectModal}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>Add Subject</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              label="New Subject"
              value={newSubject}
              onChange={(e) => setNewSubject(e.target.value)}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddSubject}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
              >
                Add
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  color: theme.palette.secondary.contrastText,
                  backgroundColor: theme.palette.secondary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary.contrastText,
                  },
                }}
                onClick={handleCloseAddSubjectModal}
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Edit Subject Dialog */}
      <Dialog
        open={openEditSubjectModal}
        onClose={handleCloseEditSubjectModal}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>Edit Subject</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              label="Subject"
              value={editSubject}
              onChange={(e) => setEditSubject(e.target.value)}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditSubject}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenDeleteSubjectConfirmModal}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  color: theme.palette.secondary.contrastText,
                  backgroundColor: theme.palette.secondary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary.contrastText,
                  },
                }}
                onClick={handleCloseEditSubjectModal}
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Delete Subject Dialog */}
      <Dialog
        open={openDeleteSubjectConfirmModal}
        onClose={handleCloseDeleteSubjectConfirmModal}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Deleting this subject will remove all associated topics and
            flashcards. Are you sure you want to proceed?
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseDeleteSubjectConfirmModal}
              sx={{
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.dark,
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleDeleteSubject();
                handleCloseDeleteSubjectConfirmModal();
              }}
              sx={{
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                  color: theme.palette.primary.contrastText,
                },
              }}
            >
              Delete
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Add Topic Dialog */}
      <Dialog
        open={openAddTopicModal}
        onClose={handleCloseAddTopicModal}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>Add Topic</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              label="New Topic"
              value={newTopic}
              onChange={(e) => setNewTopic(e.target.value)}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddTopic}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
              >
                Add
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  color: theme.palette.secondary.contrastText,
                  backgroundColor: theme.palette.secondary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary.contrastText,
                  },
                }}
                onClick={handleCloseAddTopicModal}
              >
                Close
              </Button>
            </Box>{" "}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Edit Topic Dialog */}
      <Dialog
        open={openEditTopicModal}
        onClose={handleCloseEditTopicModal}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>Edit Topic</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              label="Topic"
              value={editTopic}
              onChange={(e) => setEditTopic(e.target.value)}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              {" "}
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditTopic}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenDeleteTopicConfirmModal}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  color: theme.palette.secondary.contrastText,
                  backgroundColor: theme.palette.secondary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary.contrastText,
                  },
                }}
                onClick={handleCloseEditTopicModal}
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Delete Topic Dialog */}
      <Dialog
        open={openDeleteTopicConfirmModal}
        onClose={handleCloseDeleteTopicConfirmModal}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        {" "}
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Deleting this topic will remove all associated flashcards. Are you
            sure you want to proceed?
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseDeleteTopicConfirmModal}
              sx={{
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.dark,
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleDeleteTopic();
                handleCloseDeleteTopicConfirmModal();
              }}
              sx={{
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                  color: theme.palette.primary.contrastText,
                },
              }}
            >
              Delete
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Add Flashcard Dialog */}
      <Dialog
        open={openAddFlashcardModal}
        onClose={handleCloseAddFlashcardModal}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>Add Flashcard</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              label="Flashcard Front"
              value={newFlashcardFront}
              onChange={(e) => setNewFlashcardFront(e.target.value)}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
            />
            <TextField
              label="Flashcard Back"
              value={newFlashcardBack}
              onChange={(e) => setNewFlashcardBack(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddFlashcard}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
              >
                Add
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  color: theme.palette.secondary.contrastText,
                  backgroundColor: theme.palette.secondary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary.contrastText,
                  },
                }}
                onClick={handleCloseAddFlashcardModal}
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Edit Flashcard Dialog */}
      <Dialog
        open={openEditFlashcardModal}
        onClose={handleCloseEditFlashcardModal}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>Edit Flashcard</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              label="Flashcard Front"
              value={editFlashcardFront}
              onChange={(e) => setEditFlashcardFront(e.target.value)}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
            />
            <TextField
              label="Flashcard Back"
              value={editFlashcardBack}
              onChange={(e) => setEditFlashcardBack(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditFlashcard}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenDeleteFlashcardConfirmModal}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  color: theme.palette.secondary.contrastText,
                  backgroundColor: theme.palette.secondary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary.contrastText,
                  },
                }}
                onClick={handleCloseEditFlashcardModal}
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Delete Flashcard Dialog */}
      <Dialog
        open={openDeleteFlashcardConfirmModal}
        onClose={handleCloseDeleteFlashcardConfirmModal}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Are you sure you want to delete this flashcard?
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseDeleteFlashcardConfirmModal}
              sx={{
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.dark,
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleDeleteFlashcard();
                handleCloseDeleteFlashcardConfirmModal();
              }}
              sx={{
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                  color: theme.palette.primary.contrastText,
                },
              }}
            >
              Delete
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Generate Flashcard Dialog */}
      <Dialog
        open={openGenerateFlashcardModal}
        onClose={handleCloseGenerateFlashcardModal}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>Generate Flashcards</DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Use our AI Model to generate flashcards from your notes. The
              flashcards will be automatically added to the topic you are
              currently viewing.
            </Typography>
            <TextField
              label="Notes"
              value={generateFlashcardNotes}
              onChange={(e) => {
                const text = e.target.value;
                const words = text.split(/\s+/).filter(Boolean);
                if (words.length <= 3000) {
                  setGenerateFlashcardNotes(text);
                  setWordCount(words.length);
                }
              }}
              fullWidth
              multiline
              rows={10}
              sx={{ mb: 2 }}
              disabled={loading}
              helperText={`${wordCount}/3000 words`}
            />
            {flashcardRL > 0 &&
              !premiumEnabled &&
              new Date(flashcardResetRL) > new Date() && (
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.main }}
                >
                  Used {flashcardRL}/2 flashcard generation. Resetting at{" "}
                  {flashcardResetRL}. Please refresh the page to get the latest
                  information.
                </Typography>
              )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateFlashcards}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                disabled={loading}
              >
                Generate Flashcards
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  color: theme.palette.secondary.contrastText,
                  backgroundColor: theme.palette.secondary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary.contrastText,
                  },
                }}
                onClick={handleCloseGenerateFlashcardModal}
                disabled={loading}
              >
                Close
              </Button>
            </Box>
          </Box>
          {loading && (
            <Box sx={{ mt: 4 }}>
              <LinearProgress color="primary" />
              <Typography
                variant="body1"
                sx={{ color: theme.palette.text.primary, mt: 2 }}
                align="center"
              >
                {progressText}
              </Typography>
            </Box>
          )}

          {errorMessage && (
            <Box sx={{ mt: 3 }}>
              <Typography
                variant="body1"
                sx={{ color: theme.palette.primary.main }}
              >
                {errorMessage}
              </Typography>
            </Box>
          )}

          {successMessage && (
            <Box sx={{ mt: 3 }}>
              <Typography
                variant="body1"
                sx={{ color: theme.palette.success.main }}
              >
                {successMessage}
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default FlashcardMaker;
