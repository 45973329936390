import { createTheme, ThemeOptions } from "@mui/material/styles";

const darkThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#f51000",
      contrastText: "#000000",
    },
    secondary: {
      main: "#00e4f5",
      contrastText: "#000000",
    },
    divider: "#ffffff",
    text: {
      primary: "rgb(255, 255, 255)",
      secondary: "rgba(255, 255, 255, 0.6)",
      disabled: "rgba(255, 255, 255, 0.38)",
    },
    background: {
      default: "#121212",
    },
  },
};
export const darkTheme = createTheme(darkThemeOptions);

{
  /*
const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#ff1b0a",
      contrastText: "#000000",
    },
    secondary: {
      main: "#0aefff",
      contrastText: "#000000",
    },
    divider: "#000000",
    text: {
      primary: "rgb(0, 0, 0)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    background: {
      default: "#ffffff",
    },
  },
};
*/
}
