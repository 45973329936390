import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import Logo from "../General/Logo";

export default function ConfirmEmail() {
  const theme = useTheme();

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        position: "relative",
        backgroundColor: theme.palette.background.default,
        padding: 4,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "150%",
          height: "150%",
          top: "-25%",
          left: "-25%",
          background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
          transform: "rotate(-25deg)",
          zIndex: 1,
        }}
      />

      <Box
        sx={{
          zIndex: 10,
          backgroundColor: theme.palette.background.default,
          borderRadius: 2,
          padding: { xs: 4, md: 6 },
          boxShadow: theme.shadows[5],
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo />
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        >
          <Avatar sx={{ m: 1, bgcolor: theme.palette.success.main }}>
            <CheckCircleOutlineIcon />
          </Avatar>
        </motion.div>

        <Typography component="h1" variant="h5" sx={{ fontWeight: "bold" }}>
          Check Your Email
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: theme.palette.text.primary,
            mt: 2,
            textAlign: "center",
          }}
        >
          Please check your inbox and click on the link to confirm your account.
          After this you can log in.
        </Typography>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <Button
            fullWidth
            variant="contained"
            href="/homepage"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Homepage
          </Button>
        </motion.div>
      </Box>
    </Container>
  );
}
