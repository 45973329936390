import { Box, Container, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import logo from "../../assets/Logo.png";

const Contact = () => {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: 4,
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* Logo */}
      <Box sx={{ marginBottom: 4 }}>
        <Link href="/homepage">
          <img src={logo} alt="Company Logo" style={{ maxWidth: "150px" }} />
        </Link>
      </Box>

      {/* Contact Form */}
      <Container
        sx={{
          backgroundColor: theme.palette.background.default,
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: "600px",
          color: theme.palette.text.primary,
        }}
      >
        <Typography variant="h3" gutterBottom>
          Contact Us
        </Typography>

        <Typography variant="body1" paragraph>
          If you have any questions, feedback, or need further assistance,
          please email us directly at
          <Link href="mailto:support@actq.co.uk" sx={{ ml: 1 }}>
            support@actq.co.uk
          </Link>
          .
        </Typography>
      </Container>
    </Box>
  );
};

export default Contact;
